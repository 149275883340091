<template>
  <div>
    <div class="columns is-mobile">
      <div class="column">
        <p class="subtitle has-text-primary">Autocheck Certificate</p>
      </div>
      <div class="column is-narrow">
        <a class="has-text-danger" @click="cancel">Cancel</a>
      </div>
    </div>
    <div class="content">
      <p>
        Please select the type of certificate you want to download
      </p>
    </div>
    <div class="columns">
      <div class="column is-6">
        <div class="box">
          <div class="columns is-centered">
            <img
              class="column is-narrow"
              :src="require('core/img/icons/generic-certificate.svg')"
              width="150"
            />
          </div>
          <div class="content">
            <p class="has-text-primary">Generic AutoCheck certificate</p>
            <p>Download an AutoCheck summary certificate</p>
          </div>
          <div class="buttons is-centered">
            <button
              class="button is-rounded is-soft"
              @click="downloadGenericCertificate"
            >
              Download
            </button>
          </div>
        </div>
      </div>
      <div class="column is-6">
        <div class="box">
          <div class="columns is-centered">
            <img
              class="column is-narrow"
              :src="require('core/img/icons/franchise-certificate.svg')"
              width="150"
            />
          </div>
          <div class="content">
            <p class="has-text-primary">Franchise AutoCheck certificate</p>
            <p>Download a manufacturer branded trade certificate</p>
          </div>
          <div class="buttons is-centered">
            <button @click="customise" class="button is-white has-text-soft">
              <span>Customise</span>
              <span class="icon has-text-soft is-size-7-mobile">
                <i class="far fa-arrow-right" />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AutoCheckCertificateOptions',
  props: {
    optionsData: {
      type: Object,
      required: true
    }
  },
  methods: {
    downloadGenericCertificate() {
      this.optionsData.downloadGenericCertifcate()
      this.track('download_generic_pdf')
      this.$modal.close()
    },
    customise() {
      this.track('click_customise')
      this.$emit('next')
    },
    cancel() {
      this.track('modal_cancel')
      this.$modal.close()
    },
    track(action) {
      this.$mxp.track(`autocheck_certificates_${action}`)
    }
  }
}
</script>
